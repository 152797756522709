import React from 'react';
import { graphql, Link } from 'gatsby';
import Layout from '../../components/layouts/layout';
import SEO from '../../components/seo';
import Articles from '../../components/blog/articles';

export const query = graphql`
  query GetAuthorPageSettingsWithLocale($locale: String!, $author_id: String!) {
    pageSettings: contentfulBlogPageSettings(node_locale: { eq: $locale }) {
      title
      description {
        description
      }
      keywords
      mainHeading
      categoriesText
      shareCalloutText
      paginationPrevious
      paginationNext
    }
    author: contentfulBlogAuthor(id: { eq: $author_id }) {
      firstName
      lastName
      slug
      bio {
        bio
      }
    }
    articles: allContentfulBlogArticle(
      filter: { node_locale: { eq: $locale }, author: { id: { eq: $author_id } } }
      limit: 1000
    ) {
      nodes {
        title
        slug
        excerpt {
          excerpt
        }
        categories {
          title
          slug
        }
        author {
          firstName
          lastName
          slug
          avatar {
            resize(width: 100, height: 100) {
              src
            }
          }
        }
      }
    }
  }
`;

function AuthorPage({ data }) {
  const { pageSettings, articles, author } = data;
  return (
    <Layout>
      <SEO
        title={pageSettings.title}
        description={pageSettings.description.description}
        keywords={pageSettings.keywords}
      />
      <section
        className="flex flex-col max-w-4xl w-full m-auto"
        itemScope
        itemType="https://schema.org/Blog"
      >
        <header className="my-8">
          <h1 className="font-extrabold md:text-5xl text-left">
            Author:{' '}
            <Link className="text-theme-purple" to={`/blog/author/${author.slug}`}>
              {`${author.firstName} ${author.lastName}`}
            </Link>
          </h1>
          <p className="text-lg md:text-2xl text-gray-700">{author.bio.bio}</p>
        </header>
        <main className="leading-normal">
          <Articles pageSettings={pageSettings} articles={articles.nodes} />
        </main>
      </section>
    </Layout>
  );
}

export default AuthorPage;
